import PublicLayout from 'layouts/PublicLayout.vue'


const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/IndexPage.vue')
      },
      {
        path: 'assessments',
        name: 'assessments',
        component: () => import('pages/AssessmentsPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'questionnaire/:id',
        name: 'questionnaire',
        component: () => import('pages/QuestionnairePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('pages/ReportPage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('pages/ProfilePage.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'admin/questionnaires',
        name: 'admin-questionnaires',
        component: () => import('pages/AdminQuestionnaires.vue'),

      },
    ]
  },
  {
    path: '/public-report/:userId',
    component: PublicLayout,
    children: [
      {
        path: '',
        name: 'publicReport',
        component: () => import('pages/PublicReportPage.vue')
      }
    ]
  },
  {
    path: '/login',
    component: PublicLayout,
    name: 'login',
    component: () => import('pages/LoginPage.vue')
  },
  {
    path: '/profile-completion',
    component: PublicLayout,
    name: 'profile-completion',
    component: () => import('pages/ProfileCompletion.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
