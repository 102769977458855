import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyClOCWKM0WdbETk8Sw-z-YyoLv7iswUOXI",
  authDomain: "unleashed-potential-sa.firebaseapp.com",
  projectId: "unleashed-potential-sa",
  storageBucket: "unleashed-potential-sa.appspot.com",
  messagingSenderId: "566069243070",
  appId: "1:566069243070:web:d0e86fd7fb2e4bb2b902ef",
  measurementId: "G-JGFH6BFXYD"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider};
