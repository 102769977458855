<!-- src/components/LanguageSwitcher.vue -->
<template>
  <q-btn-dropdown flat :label="currentLanguage">
    <q-list>
      <q-item clickable v-close-popup @click="changeLanguage('en-US')">
        <q-item-section>
          <q-item-label>English</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="changeLanguage('ar')">
        <q-item-section>
          <q-item-label>العربية</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import { authService } from '../services/authService';
import { firestoreService } from '../services/firestoreService';

const { locale } = useI18n();
const $q = useQuasar();

const currentLanguage = computed(() => {
  return locale.value === 'en-US' ? 'English' : 'العربية';
});

const changeLanguage = async (lang) => {
  locale.value = lang;
  document.dir = lang === 'ar' ? 'rtl' : 'ltr';

  const quasarLangPack = lang === 'ar'
    ? await import('quasar/lang/ar')
    : await import('quasar/lang/en-US');

  $q.lang.set(quasarLangPack.default);

  // Save to localStorage
  localStorage.setItem('appLanguage', lang);

  // Update user's language preference if logged in
  const user = authService.getCurrentUser();
  if (user) {
    try {
      await firestoreService.updateUserProfile(user.uid, { language: lang });
    } catch (error) {
      console.error("Error updating user language preference:", error);
    }
  }
};
</script>
