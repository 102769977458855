<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-white text-dark">
      <q-toolbar class="justify-center">
        <div class="row items-center q-py-md">
          <q-avatar size="80px" class="q-mr-md">
            <img src="~assets/logo.png" alt="Unleashed Potential Logo">
          </q-avatar>
          <div class="text-center">
            <div class="text-h4 text-weight-bold">{{ $t('app.title') }}</div>
            <div class="text-subtitle1">{{ $t('app.subtitle') }}</div>
          </div>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.q-toolbar {
  min-height: 120px;
}

.q-avatar {
  img {
    object-fit: contain;
  }
}
</style>
