/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-US.js'



import {QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QBtnDropdown,QForm,QFile,QInput,QToggle,QTable,QTd,QCard,QCardSection,QCardActions,QBadge,QRating,QSpinnerDots,QChip,QLinearProgress,QSpace,QDialog,QBreadcrumbs,QBreadcrumbsEl,QCircularProgress,QAvatar,QSeparator,QMarkupTable,Ripple,ClosePopup,Notify,Dialog,LocalStorage,SessionStorage,Loading,Meta} from 'quasar'



export default { config: {"brand":{"primary":"#3498db","secondary":"#2c3e50","accent":"#e74c3c","dark":"#2c3e50","positive":"#27ae60","negative":"#e74c3c","info":"#3498db","warning":"#f1c40f","light-gray":"#ecf0f1","background-light":"#f5f5f5","background-dark":"#bdc3c7"},"supportRTL":true},lang,components: {QLayout,QHeader,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QBtnDropdown,QForm,QFile,QInput,QToggle,QTable,QTd,QCard,QCardSection,QCardActions,QBadge,QRating,QSpinnerDots,QChip,QLinearProgress,QSpace,QDialog,QBreadcrumbs,QBreadcrumbsEl,QCircularProgress,QAvatar,QSeparator,QMarkupTable},directives: {Ripple,ClosePopup},plugins: {Notify,Dialog,LocalStorage,SessionStorage,Loading,Meta} }

