// src/services/authService.js

import { auth } from '../firebase/config';
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth';
import { firestoreService } from './firestoreService';

export const authService = {
  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      await this.createOrUpdateUserProfile(result.user);
      return result.user;
    } catch (error) {
      console.error("Error signing in with Google", error);
      throw error;
    }
  },

  async signInWithEmail(email, password) {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (error) {
      console.error("Error signing in with email", error);
      throw error;
    }
  },

  async signUp(email, password, displayName) {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(result.user, { displayName });
      await this.createOrUpdateUserProfile(result.user);
      return result.user;
    } catch (error) {
      console.error("Error signing up", error);
      throw error;
    }
  },

  async signOut() {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
      throw error;
    }
  },

  getCurrentUser() {
    return auth.currentUser;
  },

  onAuthStateChanged(callback) {
    return onAuthStateChanged(auth, callback);
  },

  async createOrUpdateUserProfile(user) {
    const userProfile = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      lastLogin: new Date(),
    };

    try {
      const existingProfile = await firestoreService.getUserProfile(user.uid);
      if (existingProfile) {
        await firestoreService.updateUserProfile(user.uid, userProfile);
      } else {
        await firestoreService.createUserProfile(user.uid, userProfile);
      }
    } catch (error) {
      console.error("Error creating/updating user profile", error);
      throw error;
    }
  },

  async getUserData(userId) {
    try {
      return await firestoreService.getUserProfile(userId);
    } catch (error) {
      console.error("Error fetching user data", error);
      throw error;
    }
  },

  async completeUserProfile(userId, profileData) {
    try {
      await firestoreService.updateUserProfile(userId, {
        ...profileData,
        profileCompleted: true
      });
    } catch (error) {
      console.error("Error completing user profile", error);
      throw error;
    }
  },

  async isProfileCompleted(userId) {
    try {
      const userProfile = await firestoreService.getUserProfile(userId);
      return userProfile && userProfile.profileCompleted;
    } catch (error) {
      console.error("Error checking if profile is completed", error);
      return false;
    }
  },

  async getCurrentUserLanguage() {
    const user = auth.currentUser;
    if (user) {
      const userData = await firestoreService.getUserProfile(user.uid);
      return userData?.language || 'en-US'; // Default to English if no preference is set
    }
    return 'en-US';
  },

  async isUserAdmin(userId) {
    try {
      const userProfile = await firestoreService.getUserProfile(userId);
      return userProfile && userProfile.isAdmin;
    } catch (error) {
      console.error("Error checking if user is admin", error);
      return false;
    }
  },
};
