import { db } from '../firebase/config';
import {
  collection, doc, addDoc, setDoc, getDoc, getDocs,
  updateDoc, deleteDoc, query, where, serverTimestamp,
  increment
} from 'firebase/firestore';

export const firestoreService = {
  // Questionnaire methods
  async addQuestionnaire(questionnaireData) {
    // Recursively remove undefined values
    const removeUndefined = (obj) => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          removeUndefined(obj[key]);
        } else if (obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    };

    const cleanedData = removeUndefined(JSON.parse(JSON.stringify(questionnaireData)));

    console.log('Cleaned questionnaire data:', JSON.stringify(cleanedData, null, 2));

    const docRef = await addDoc(collection(db, 'questionnaires'), {
      ...cleanedData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    const metadataFields = {
      id: docRef.id,
      title: cleanedData.title,
      arabicTitle: cleanedData.arabicTitle,
      description: cleanedData.description,
      questionCount: cleanedData.questionCount,
      estimatedTime: cleanedData.estimatedTime,
      isEnabled: cleanedData.isEnabled,
      isPaid: cleanedData.isPaid,
      price: cleanedData.price,
      ranking: cleanedData.ranking,
    };

    await this.createQuestionnaireMetadata(metadataFields);
    return docRef.id;
  },

  async getQuestionnaire(id) {
    const docRef = doc(db, 'questionnaires', id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
  },

  async updateQuestionnaire(id, data) {
    const docRef = doc(db, 'questionnaires', id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: serverTimestamp()
    });
    await this.updateQuestionnaireMetadata(id, {
      title: data.title,
      arabicTitle: data.arabicTitle,
      description: data.description,
      questionCount: this.calculateQuestionCount(data),
      estimatedTime: this.estimateCompletionTime(data),
      isEnabled: data.isEnabled,
      isPaid: data.isPaid,
      price: data.price,
      ranking: data.ranking,
      updatedAt: serverTimestamp()
    });
  },
  async deleteQuestionnaire(id) {
    await deleteDoc(doc(db, 'questionnaires', id));
    await this.deleteQuestionnaireMetadata(id);
  },

  async getFullQuestionnaires() {
    const querySnapshot = await getDocs(collection(db, 'questionnaires'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  // Questionnaire Metadata methods
  async createQuestionnaireMetadata(metadata) {
    try {
      const docRef = doc(db, 'questionnaireMetadata', metadata.id);
      await setDoc(docRef, {
        ...metadata,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      return metadata.id;
    } catch (error) {
      console.error("Error creating questionnaire metadata: ", error);
      throw error;
    }
  },

  async getQuestionnaireMetadata() {
    const querySnapshot = await getDocs(collection(db, 'questionnaireMetadata'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async updateQuestionnaireMetadata(id, data) {
    const docRef = doc(db, 'questionnaireMetadata', id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: serverTimestamp()
    });
  },

  async deleteQuestionnaireMetadata(id) {
    await deleteDoc(doc(db, 'questionnaireMetadata', id));
  },

  // User Progress methods
  async getUserQuestionnaireProgress(userId) {
    const q = query(collection(db, 'userQuestionnaireProgress'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async saveQuestionnaireProgress(userId, questionnaireId, progressData) {
    const progressRef = doc(db, 'userQuestionnaireProgress', `${userId}_${questionnaireId}`);
    await setDoc(progressRef, {
      userId,
      questionnaireId,
      ...progressData,
      lastUpdated: serverTimestamp()
    }, { merge: true });
  },

  async getQuestionnaireProgress(userId, questionnaireId) {
    const progressRef = doc(db, 'userQuestionnaireProgress', `${userId}_${questionnaireId}`);
    const docSnap = await getDoc(progressRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  // User Responses methods
  async submitQuestionnaireResponses(userId, questionnaireId, answers) {
    const responseRef = doc(db, 'userQuestionnaireResponses', `${userId}_${questionnaireId}`);
    await setDoc(responseRef, {
      userId,
      questionnaireId,
      answers,
      submittedAt: serverTimestamp()
    });

    // Mark the questionnaire as completed in the progress collection
    await this.saveQuestionnaireProgress(userId, questionnaireId, {
      completed: true,
      completedAt: serverTimestamp()
    });

    // Generate and save the report
    await this.generateAndSaveReport(userId, questionnaireId, answers);
  },

  async getQuestionnaireResults(userId, questionnaireId) {
    const docRef = doc(db, 'userQuestionnaireResponses', `${userId}_${questionnaireId}`);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  // User Reports methods
  async generateAndSaveReport(userId, questionnaireId, answers) {
    const questionnaire = await this.getQuestionnaire(questionnaireId);
    const report = this.calculateReport(questionnaire, answers);
    await this.saveUserReport(userId, questionnaireId, report);
  },

  async saveUserReport(userId, questionnaireId, reportData) {
    const reportRef = doc(db, 'userReports', `${userId}_${questionnaireId}`);
    await setDoc(reportRef, {
      ...reportData,
      userId,
      questionnaireId,
      createdAt: serverTimestamp()
    });
  },

  async getUserReport(userId, questionnaireId) {
    const docRef = doc(db, 'userReports', `${userId}_${questionnaireId}`);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
  },

  async getUserReports(userId) {
    const q = query(collection(db, 'userReports'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  // Purchase methods
  async purchaseQuestionnaire(userId, questionnaireId) {
    const docRef = doc(db, 'userQuestionnaireProgress', `${userId}_${questionnaireId}`);
    await setDoc(docRef, {
      userId,
      questionnaireId,
      isPurchased: true,
      purchasedAt: serverTimestamp()
    }, { merge: true });
  },

  // User Profile methods
  async getUserProfile(userId) {
    try {
      const userRef = doc(db, 'users', userId);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user profile: ", error);
      throw error;
    }
  },

  async updateUserProfile(userId, profileData) {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        ...profileData,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error("Error updating user profile: ", error);
      throw error;
    }
  },

  async createUserProfile(userId, profileData) {
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, {
        ...profileData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error("Error creating user profile: ", error);
      throw error;
    }
  },

  // Credits methods
  async addCredits(userId, creditsAmount) {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        credits: increment(creditsAmount),
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error("Error adding credits: ", error);
      throw error;
    }
  },

  async incrementPublicReportViews(userId) {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        publicReportViews: increment(1)
      });
    } catch (error) {
      console.error("Error incrementing public report views: ", error);
      throw error;
    }
  },
  // Helper methods
  calculateQuestionCount(questionnaireData) {
    return questionnaireData.categories.reduce((total, category) =>
      total + category.sections.reduce((sectionTotal, section) =>
        sectionTotal + section.questions.length, 0), 0);
  },

  estimateCompletionTime(questionnaireData) {
    const questionCount = this.calculateQuestionCount(questionnaireData);
    return Math.ceil(questionCount * 0.5); // Assuming 30 seconds per question, return in minutes
  },

  calculateReport(questionnaire, answers) {
    const mainCategories = {};
    let totalScore = 0;
    let totalQuestions = 0;

    // Calculate scores for each main measure and submeasure
    questionnaire.categories.forEach(category => {
      let categoryScore = 0;
      let categoryQuestions = 0;
      const submeasures = {};

      category.sections.forEach(section => {
        let sectionScore = 0;
        let sectionQuestions = 0;

        section.questions.forEach(question => {
          if (answers[question.variable_name] !== undefined) {
            sectionScore += answers[question.variable_name];
            sectionQuestions++;
          }
        });

        if (sectionQuestions > 0) {
          const sectionAverageScore = sectionScore / sectionQuestions;
          submeasures[section.name] = sectionAverageScore;
          categoryScore += sectionScore;
          categoryQuestions += sectionQuestions;
        }
      });

      if (categoryQuestions > 0) {
        const categoryAverageScore = categoryScore / categoryQuestions;
        mainCategories[category.name] = {
          score: categoryAverageScore,
          submeasures: submeasures
        };
        totalScore += categoryScore;
        totalQuestions += categoryQuestions;
      }
    });

    const overallScore = totalQuestions > 0 ? totalScore / totalQuestions : 0;

    // Determine strength areas and areas for improvement
    const strengthThreshold = 4;
    const improvementThreshold = 3;

    const strengthAreas = Object.entries(mainCategories)
      .filter(([, data]) => data.score >= strengthThreshold)
      .map(([category]) => category);

    const improvementAreas = Object.entries(mainCategories)
      .filter(([, data]) => data.score < improvementThreshold)
      .map(([category]) => category);

    // Generate recommendations
    const recommendations = [];
    if (improvementAreas.length > 0) {
      recommendations.push(`Focus on improving your skills in: ${improvementAreas.join(', ')}.`);
    }
    if (strengthAreas.length > 0) {
      recommendations.push(`Leverage your strengths in: ${strengthAreas.join(', ')} to excel in your career.`);
    }
    if (overallScore < 3) {
      recommendations.push("Consider seeking additional training or mentorship to enhance your overall competencies.");
    } else if (overallScore >= 4) {
      recommendations.push("Your overall performance is strong. Consider taking on leadership roles or mentoring others.");
    }

    return {
      title: questionnaire.title,
      overallScore,
      mainCategories,
      strengthAreas,
      improvementAreas,
      recommendations
    };
  }
};
