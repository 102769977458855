import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { authService } from '../services/authService'

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  // Add this navigation guard
  Router.afterEach((to, from) => {
    if (window.clarity) {
      window.clarity("set", "page_view", to.path);
    }
  })

  Router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isPublicReport = to.name === 'publicReport'
    const currentUser = authService.getCurrentUser()

    if (requiresAuth && !currentUser) {
      next('/login')
    } else if (isPublicReport) {
      // Always allow access to public report
      next()
    } else if (to.path === '/login' && currentUser) {
      next('/')
    } else {
      next()
    }
  })

  return Router
})
