<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="bg-white text-dark">
      <q-toolbar>
        <q-toolbar-title>
          {{ $t('app.title') }}
        </q-toolbar-title>
        <LanguageSwitcher />
        <q-btn v-if="user" flat @click="signOut" :label="$t('nav.signOut')" />
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useQuasar } from 'quasar';
import { authService } from './services/authService';
import { firestoreService } from './services/firestoreService';
import LanguageSwitcher from './components/LanguageSwitcher.vue';

const user = ref(null);
const $q = useQuasar();
const { t, locale } = useI18n();
const router = useRouter();

const setLanguage = async (userId) => {
  try {
    let userLang;
    if (userId) {
      const userProfile = await firestoreService.getUserProfile(userId);
      userLang = userProfile?.language || 'en-US';
    } else {
      userLang = localStorage.getItem('appLanguage') || 'en-US';
    }

    locale.value = userLang;
    document.dir = userLang === 'ar' ? 'rtl' : 'ltr';

    // Import the Quasar language pack dynamically
    const quasarLangPack = userLang === 'ar'
      ? await import('quasar/lang/ar')
      : await import('quasar/lang/en-US');

    $q.lang.set(quasarLangPack.default);

    // Save the language preference to localStorage
    localStorage.setItem('appLanguage', userLang);
  } catch (error) {
    console.error('Error setting language:', error);
  }
};

onMounted(async () => {
  const currentUser = authService.getCurrentUser();
  if (currentUser) {
    await setLanguage(currentUser.uid);
  } else {
    await setLanguage();
  }
});

// Watch for auth state changes and update language accordingly
watch(() => authService.getCurrentUser(), async (currentUser) => {
  user.value = currentUser;
  if (currentUser) {
    await setLanguage(currentUser.uid);
  } else {
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push('/login');
    }
    // If user logs out, use the last known language preference
    await setLanguage();
  }
}, { immediate: true });

let unsubscribe;
onMounted(() => {
  unsubscribe = authService.onAuthStateChanged((currentUser) => {
    user.value = currentUser;
    if (!currentUser && router.currentRoute.value.meta.requiresAuth) {
      router.push('/login');
    }
  });
});

onUnmounted(() => {
  if (unsubscribe) unsubscribe();
});

async function signOut() {
  try {
    await authService.signOut();
    $q.notify({
      color: 'positive',
      textColor: 'white',
      icon: 'logout',
      message: t('notifications.signOutSuccess')
    });
    router.push('/login');
  } catch (error) {
    console.error('Sign out error:', error);
    $q.notify({
      color: 'negative',
      textColor: 'white',
      icon: 'error',
      message: t('notifications.signOutError')
    });
  }
}
</script>

<style lang="scss">
// Add any global styles here
</style>
